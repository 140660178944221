import React, { useState , useRef } from 'react';
import styles from './projects.module.css';
import { Toast } from 'primereact/toast';
import NotchPay from 'notchpay.js';

const notchpay = NotchPay("pk_test.lWEI93Eo2BMg5ALaO14yLhTc8HUF9RGEwu07OJyHYCKaPmhGiFJkQma5UIVuKfXb8NO9bz0MlrmuOX0EeL9mfPSStCSM2fPvwJ6dpQfEErsvkHbUtbXRJmIb01RUI", { debug: true });
var ispaid = false

export const Ispaid= ()=>{
    alert(ispaid)
    setTimeout(Ispaid, 5000);
    return(ispaid)}


export  function Payements() {
    const [paymentInitiated, setPaymentInitiated] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef(null);


    const handlePayment = async () => {
        setIsLoading(true);
        try {
            // Initialisation du paiement
            const payment = await notchpay.payments.initializePayment({
                currency: "XAF",
                amount: "2500",
                email: "komoldaniel72@gmail.com",
                phone: "658670727",
                reference: "ref." + (Math.floor(Math.random() * (2000 - 100 + 1)) + 100),
                description: "Payment for testing the Notch Pay SDK"
            });
            setPaymentInitiated(payment);

            // Complétion du paiement
            if (payment.transaction) {
                const result = await notchpay.payments.completePayment(
                    payment.transaction.reference,
                    {
                        channel: 'string',
                        data: {
                            phone: '658670727'
                        }
                    }
                );
                console.log("Paiement complété:", result);
                ispaid = true;
                toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Payement effectue avec succès' });
                

            }
        } catch (error) {
            console.error("Erreur lors du paiement:", error);
            toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors du paiement' });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>    
            <Toast ref={toast} />
            <h3>Choisir un moyen de paiement</h3>
            <div className={styles.paymentOption}>
                <img src={require('./img/orangemoney.jpg')} alt="Orange Money" className={styles.paymentIcon} />
                <img src={require('./img/Mtn.png')} alt="MTN Mobile Money" className={styles.paymentIcon} />
                <img src={require('./img/bank.jpg')} alt="MTN Mobile Money" className={styles.paymentIcon} />
                <div>
                    <h4>2500 Fcfa</h4>
                    <button className={styles.button} onClick={handlePayment} disabled={isLoading}>
                        {isLoading ? 'Traitement...' : 'Proceder au checkout'}
                    </button>
                </div>
            </div>
        </div>
    );
}