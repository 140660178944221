import React, { useState, useRef, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Steps } from 'primereact/steps';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { ContextMenu } from 'primereact/contextmenu';
import styles from './templates.module.css';
import MainLayout from '../layouts/MainLayout';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import axios, { baseURL } from '../utils/axiosConfig';
import { useSelector } from 'react-redux';

export default function TemplatesPage() {
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [templateName, setTemplateName] = useState('');
  const [templateType, setTemplateType] = useState(null);
  const [selectedBaseTemplate, setSelectedBaseTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [isCreateDialogVisible, setCreateDialogVisible] = useState(false);
  const [isRenameDialogVisible, setRenameDialogVisible] = useState(false);
  const [renameTemplate, setRenameTemplate] = useState(null);
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [baseTemplates, setBaseTemplates] = useState(null);
  const toast = useRef(null);
  const contextMenu = useRef(null);
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const templateRef = useRef(null);

  const templateTypes = [
    { label: 'Tous', value: null },
    { label: 'Site web', value: 'web' },
    { label: 'Application web', value: 'web_app' },
    { label: 'Application mobile', value: 'mobile_app' }
  ];

  const steps = [
    { label: 'Nom du template' },
    { label: 'Choisir un template de base' }
  ];

  const contextMenuItems = [
    { label: 'Ouvrir', icon: 'pi pi-folder-open', command: () => navigate(`/editeur/${selectedTemplate.id}/?type=template`) },
    { label: 'Renommer', icon: 'pi pi-pencil', command: () => { setRenameDialogVisible(true); setRenameTemplate(selectedTemplate); } },
    { label: 'Supprimer', icon: 'pi pi-trash', command: () => { setRenameTemplate(selectedTemplate); setConfirmDeleteVisible(true); } },
    { label: 'Changer le montant', icon: 'pi pi-money-bill', command: () => { /* Logique pour changer le montant */ } },
  ];

  const getTemplates = async () => {
    try {
      const response = await axios.get('/templates', {
        headers: { Authorization: `Bearer ${token.access}` }
      });
      setBaseTemplates(response.data);
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la récupération des templates 1' });
    }
  };

  const getOwnTemplates = async () => {
    try {
      const response = await axios.get('/selves-templates/', {
        headers: { Authorization: `Bearer ${token.access}` }
      });
      setTemplates(response.data);
      setFilteredTemplates(response.data); 
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la récupération des templates' });
    }
  };

  useEffect(() => {
    getTemplates();
    getOwnTemplates();
  }, []);

  const createTemplate = () => {
    axios.post('/templates/create/', { name: templateName, type: templateType, base_template: selectedBaseTemplate }, {
      headers: { Authorization: `Bearer ${token.access}` }
    })
      .then(response => {
        toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Template créé avec succès' });
        setTemplates([...templates, response.data]);
        setCreateDialogVisible(false);
        setTemplateName('');
        navigate(`/editeur/${response.data.id}/?type=template`);
      })
      .catch(error => {
        toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la création du template' });
      });
  };

  const filterTemplatesByType = (type) => {
    if (type) {
      setFilteredTemplates(templates.filter(template => template.type === type));
    } else {
      setFilteredTemplates(templates); // Afficher tous les templates si aucun filtre sélectionné
    }
  };

  const onNextStep = () => {
    if (activeIndex === 1) {
      createTemplate();
    } else {
      setActiveIndex((prev) => prev + 1);
    }
  };

  const deleteTemplate = async() => {
    try {
      await axios.delete(`/templates/${renameTemplate.id}/delete`, {
        headers: { Authorization: `Bearer ${token.access}` }
      });
      toast.current.show({ severity: 'success', summary: 'Succès', detail: 'Template supprimé avec succès' });
      setFilteredTemplates(templates.filter(template => template.id !== renameTemplate.id));
      setConfirmDeleteVisible(false);
      setRenameTemplate(null);
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors de la suppression du template' });
    }
  };

  const handleRightClick = (e, template) => {
    e.preventDefault(); // Annule le menu contextuel par défaut du navigateur
    setSelectedTemplate(template);
    contextMenu.current.show(e);
  };

  const handleFileChange = async(e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('template_zip', file);

    try {
      const response = await axios.post('/templates/upload/', formData, {
        headers: { Authorization: `Bearer ${token.access}`,
      'Content-Type': 'multipart/form-data' }
      });
      console.log(response.data);
      toast.current.show({ severity:'success', summary: 'Succès', detail: 'Fichier téléchargé avec succès' });
      templateRef.current.value = null;
      getOwnTemplates();
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Erreur lors du téléchargement du fichier' });
    }
  };

  return (
    <MainLayout>
      <Toast ref={toast} />
      <ContextMenu model={contextMenuItems} ref={contextMenu} />
      <div className={styles.container}>
        <h1 className={styles.title}>Vos templates :</h1>

        <div className={styles.filter}>
          <Button  style={{color:"#f89f22"}}  onClick={()=>{if (templateRef.current ) {templateRef.current.click()}}}  icon={<i style={{color:'#f89f22'}} className='pi pi-upload'></i>} severity="warning" outlined>
            <input type='file' accept='' style={{display:'none'}}  ref={templateRef} onChange={(e)=>handleFileChange(e)}/>
            </Button>
          <Dropdown
            value={templateType}
            options={templateTypes}
            onChange={(e) => filterTemplatesByType(e.value)}
            placeholder="Filtrer par type"
            className={styles.filterDropdown}
          />
        </div>

        <div className={styles.templates}>
          <Card className={styles.addCard} onClick={() => { setCreateDialogVisible(true); setActiveIndex(0); }}>
            <div className={styles.addCardContent}>
              <span className={styles.addIcon}>+</span>
            </div>
          </Card>

          {filteredTemplates.map((template, index) => (
            <Card
            style={{backgroundColor: `${template.id === selectedTemplate? "#f89f22":"white"}`}}
              key={index}
              onClick={()=>setSelectedTemplate(template)}
              className={`${styles.templateCard} ${selectedTemplate && selectedTemplate.id === template.id ? styles.selectedTemplate : ''}`}
              onDoubleClick={() => navigate(`/editeur/${template.id}/?type=template`)}
              onContextMenu={(e) => handleRightClick(e, template)}
            >
              <div className={styles.templateContent}>
                <div className={styles.templateAvatar}>
                  <span className={styles.avatarText}>{template.name[0]}</span>
                </div>
                <div className={styles.templateDetails}>
                  <h2 className={styles.templateTitle}>{template.name}</h2>
                  <small><i>Type : </i></small><small className={styles.templateType}>{template.type_template}</small>
                  <p className={styles.templateDate}><small><i>Créé le : </i></small> {new Date(template.created_at).toLocaleDateString()}</p>
                </div>
              </div>
            </Card>
          ))}
        </div>

        <Dialog
          visible={isCreateDialogVisible}
          style={{ width: '50vw', maxHeight: '90vh', overflowY: 'auto' }}
          header="Créer un template"
          modal
          onHide={() => setCreateDialogVisible(false)}
          className={styles.customDialog}
        >
          <Steps model={steps} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />

          {activeIndex === 0 && (
            <div className={styles.stepContent}>
              <h3>Nom du template</h3>
              <InputText value={templateName} onChange={(e) => setTemplateName(e.target.value)} placeholder="Nom du template" className={styles.input} />
              <h3>Type de template</h3>
              <Dropdown value={templateType} options={templateTypes.slice(1, 4)} onChange={(e) => setTemplateType(e.value)} placeholder="Choisir le type" className={styles.input} />
            </div>
          )}

          {activeIndex === 1 && (
            <div className={styles.stepContent}>
                <h3>Choisir un template de base</h3>
                <div className={styles.templateGrid}>
                  {baseTemplates.map((template, index) => (
                      <Card key={index} className={styles.templateCard} onClick={() => setSelectedBaseTemplate(template.id)} style={{backgroundColor: `${template.id === selectedBaseTemplate? "#f89f22":"white"}`}}>
                        <img src={`${baseURL}${template.thumbnail}`} alt={template.name} className={styles.templateImage} />
                        <div className={styles.templateName}>{template.name}</div>
                      </Card>
                  ))}
              </div>
            </div>
          )}

          <div className={styles.footer}>
            {activeIndex > 0 && <Button label="Précédent" onClick={() => setActiveIndex(activeIndex - 1)} />}
            <Button label={activeIndex === 1 ? 'Créer' : 'Suivant'} onClick={onNextStep} disabled={!templateName || !templateType || (activeIndex === 1 && !selectedBaseTemplate)} />
          </div>
        </Dialog>

        <ConfirmDialog
          visible={confirmDeleteVisible}
          onHide={() => setConfirmDeleteVisible(false)}
          message="Êtes-vous sûr de vouloir supprimer ce template ?"
          header="Confirmation de suppression"
          icon="pi pi-exclamation-triangle"
          accept={deleteTemplate}
          reject={() => setConfirmDeleteVisible(false)}
        />

        <Dialog
          visible={isRenameDialogVisible}
          style={{ width: '50vw', maxHeight: '90vh', overflowY: 'auto' }}
          header="Renommer le template"
          modal
          onHide={() => setRenameDialogVisible(false)}
          className={styles.customDialog}
        >
          <div className={styles.stepContent}>
            <h3>Nouveau nom du template</h3>
            <InputText value={renameTemplate?.name || ''} onChange={(e) => setRenameTemplate({ ...renameTemplate, name: e.target.value })} placeholder="Nom du template" className={styles.input} />
          </div>
          <Button label="Renommer" onClick={()=>{ }} />
        </Dialog>
      </div>
    </MainLayout>
  );
}
