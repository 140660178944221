import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://api.build.legionweb.co',// https://api.build.legionweb.co
    headers: {
        'Content-Type': 'application/json',
    },
});
export const baseURL = 'https://api.build.legionweb.co';//http://localhost:8000

export default axiosInstance;
