import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from '../utils/axiosConfig';
import { setAuth } from '../utils/redux/slices/authSlice';
import { Toast } from 'primereact/toast';
const LoginRequireLayout = ({ children }) => {
    const navigate = useNavigate();
    const token = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const toast = useRef();

    // useEffect(() => {
    //     if (!token.token) {
    //         // Rediriger vers la page de login avec le paramètre `next`
    //         if (window.location.pathname.search('login') === -1) {
    //             toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Vous devez vous connecter pour accéder à cette page', life: 5000 });
    //             navigate(`/login?next=${window.location.pathname}`);
    //         }
    //     } else {    
    //         // Vérifier si le token est valide
    //         axios.get('/users/token/verify', { headers: { Authorization: `Bearer ${token.token.access}` } })
    //             .catch(error => {
    //                 // Si le token est expiré (erreur 401), tenter de le rafraîchir
    //                 if (error.response?.status === 401) {
    //                     axios.post('/users/token/refresh/', { refresh: token.token.refresh })
    //                         .then(res => {
    //                             // Mettre à jour le token dans Redux
    //                             const auth = { ...token, token: { refresh: token.token.refresh, access: res.data.access } };
    //                             dispatch(setAuth(auth));
    //                         })
    //                         .catch(refreshError => {
    //                             // Journaliser l'erreur et rediriger vers la page de login
    //                             console.error("Could not refresh the token:", refreshError);
    //                             if (window.location.pathname.search('login') === -1) {
    //                                 toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Votre session a expiré, vous devez vous reconnecter', life: 5000 });
    //                                 navigate(`/login?next=${window.location.pathname}`);
    //                             }
    //                         });
    //                 } else {
    //                     // Journaliser les autres erreurs et rediriger vers la page de login
    //                     console.error("Token is not valid:", error);
    //                     if (window.location.pathname.search('login') === -1) {
    //                         toast.current.show({ severity: 'error', summary: 'Erreur', detail: 'Votre session a expiré, vous devez vous reconnecter', life: 5000 });
    //                         navigate(`/login?next=${window.location.pathname}`);
    //                     }
    //                 }
    //             });
    //     }
    // }, [token, navigate]);
    

    // If not authenticated, you could return null or a loading spinner
    if (!token) {
        return null;
    }

    return (
        <div>
            <Toast ref={toast} />
            {children}
        </div>
    );
};

export default LoginRequireLayout;
